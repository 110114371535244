// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyBK39E41GYc11QnYENl7ucYnF_gJmIUMNU",
  // authDomain: "nidw-30fc2.firebaseapp.com",
  // projectId: "nidw-30fc2",
  // storageBucket: "nidw-30fc2.appspot.co",
  // messagingSenderId: "504721414814",
  // appId: "1:504721414814:web:bda189240e37577cc7b98f",

  apiKey: "AIzaSyBK39E41GYc11QnYENl7ucYnF_gJmIUMNU",
  authDomain: "nidw-f896f.firebaseapp.com",
  databaseURL: "https://nidw-30fc2-default-rtdb.firebaseio.com",
  projectId: "nidw-f896f",
  storageBucket: "nidw-f896f.appspot.comm",
  messagingSenderId: "259444564813",
  appId: "1:504721414814:web:bda189240e37577cc7b98f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const db = getDatabase();
export { app, analytics, auth, db };
